import React, { Component } from 'react';
import emergencyNums from './../../static/emergency.json';
import MyProfile from './../utils/MyProfile.js';

class HomeLanding extends Component {
	constructor(props, context) {
        super(props, context);
        this.state = {
            loginResp: props.loginResp,
            wat: props.wat,
        };
      }
  

     

     
    render() {
        return (
            
            <div className="HomeLanding container">                
                    <div className="row m-3">
                        <div className="grid-item mb-3 grid-01 col-md-6">
                            <div className="card h-100">
                                <div className="card-body">
                                <h3 className="h5 card-title mb-0 text-sans-light">Emergency Contacts</h3>
                                </div>
                                <ul className="list-group list-group-flush">
									<li className="list-group-item"><small><a href="tel:911">Fire, Police, Ambulance | Call 911</a></small></li>
									<li className="list-group-item d-md-none"><small><a href="sms:911">Fire, Police, Ambulance | Text 911</a></small></li>
                                    { emergencyNums.map( (item,index) =>  
                                        <li className="list-group-item" key={index}><small><a href={"tel:"+item.phone}>{item.name} | {item.phone}</a></small></li>                    
                                    )}                                    
                                </ul>
                            </div>
                        </div>
                      
                        <div className="grid-item mb-3 grid-01 col-md-6">
                            <div className="card h-100">
                            <div className="card-body">                                                                
                                <MyProfile loginResp={this.state.loginResp}  login={this.props.login} />                           
                            </div>
                            </div>
                        </div>
                    </div>	
                <style>
                    .loginStat  color:#ddd; 
                    .loginStat  a   color:#999; 
                </style>
            </div>
                    
        )
    
    }
}

//const HomeLanding = withRouter(HomeLandingWR);
export default HomeLanding;
 

//export default withRouter(connect(HomeLanding, {})(Component));